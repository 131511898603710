import request from '@/utils/request'
import qs from 'qs'

export function getMapPoint(data) {
    return request({
        url: `api/front/mapPoint?${qs.stringify(data)}`,
        method: 'get'
    })
}


export default { getMapPoint}