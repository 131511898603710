import request from '@/utils/request'
import qs from 'qs'

export function getAdvertising(data) {
    return request({
        url: `/api/front/advertising?${qs.stringify(data)}`,
        method: 'get'
    })
}

export function getAdvertisingPage(data) {
    return request({
        url: `/api/front/advertising/page?${qs.stringify(data)}`,
        method: 'get'
    })
}


export default { getAdvertising,getAdvertisingPage}