//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Cesium from "@c/cesium/cesium.vue";
import SearchCesium from "@c/searchCesium/searchCesium.vue";
export default {
  components: {
    Cesium,
    SearchCesium,
  },
  data() {
    return {
      searchShow: false,
    };
  },
  mounted() {
    let that = this;
    this.searchShow = true;
    setTimeout(() => {
      this.$refs.cesium.flyTo(115.479965, 31.237658, 2000000, () => {
        that.$refs.cesium.ableControl(true);
      });
      this.$refs.cesium.initEcharts();
    }, 1000);
  },
  methods: {
    searchEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    setMapPoints(division, address, adform) {
      this.$refs.cesium.setMapPoints(division, address, adform);
    },
  },
};
