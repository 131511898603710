//
//
//
//
//

import {
  Viewer,
  UrlTemplateImageryProvider,
  WebMapTileServiceImageryProvider,
  ScreenSpaceEventHandler,
  HorizontalOrigin,
  VerticalOrigin,
  Cartesian3,
  Cartesian2,
  SceneMode,
  Math as CMath,
  Color,
  defined,
  Cartographic,
  ScreenSpaceEventType,
  TextureMinificationFilter,
  TextureMagnificationFilter,
  Ion,
  CallbackProperty,
} from "cesium/Cesium.js";
import "cesium//Widgets/widgets.css";
import mapPointApi from "@/api/mapPoint";
import mapAdvertisingApi from "@/api/mapAdvertising";
import adModal from "@/components/advertising/adModal";
import EchartsLayer from "@/echartLib/echarts/EchartsLayer";
import getOption from "@/echartLib/echarts/getOptions.js";
import transformGCJ2WGS from "@/utils/coordinateTrans.js";
import CustomerInfo from "@/components/customerInfo/customerInfo.vue";
// import '@/tdtLib/lib'
const blinkPointName = '圆点point闪烁'
export default {
  components: {
    adModal,
  },
  data() {
    return {
      mapPointList: [],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    /**
     * 初始化闪烁点
     */
    initBlinkPoint() {
      var x = 1;
      var flog = true;
      viewer.entities.add({
        name: blinkPointName,
        // 上海 121.4694,31.2319
        position: Cartesian3.fromDegrees(121.4694, 31.2319, 0),
        point: {
          show: true, // default
          color: new CallbackProperty(function () {
            if (flog) {
              x = x - 0.05;
              if (x <= 0) {
                flog = false;
              }
            } else {
              x = x + 0.05;
              if (x >= 1) {
                flog = true;
              }
            }
            return Color.RED.withAlpha(x);
          }, false),
          pixelSize: 12, // default: 1
          outlineWidth: 0,
        },
        label: {
            show: false,
            showBackground: true,
            // text:"上海市",
            font: "20px monospace",
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.TOP,
            pixelOffset: new Cartesian2(15, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            color: new CallbackProperty(function () {
            if (flog) {
              x = x - 0.05;
              if (x <= 0) {
                flog = false;
              }
            } else {
              x = x + 0.05;
              if (x >= 1) {
                flog = true;
              }
            }
            return Color.RED.withAlpha(x);
          }, false),
          },
      });
    },

    initEcharts() {
      // let option = getOption();
      // let _echartLayer = new EchartsLayer(window.viewer, option);
    },
    init() {
      let cesiumAsset =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIzNzVlNzhlMi0wMzcxLTQyYzgtYTA2MC01YTY2YTc4NmU4NmMiLCJpZCI6NzU5NzAsImlhdCI6MTYzOTAxNTMzMX0.JFLhy8fckDqnGRqUISfDEtFuy3onNWCCw0jmFDWSU6g";
      let tiandituTk = "1b35bba503ef958cbcab97d5688e4038";
      Ion.defaultAccessToken = cesiumAsset;

      // 服务负载子域
      let subdomains = ["0", "1", "2", "3", "4", "5", "6", "7"];
      const viewer = new Viewer("cesiumContainer", {
        animation: false, // 是否创建动画小部件
        timeline: false, // 是否显示时间线控件
        fullscreenButton: false, // 右下角全屏按钮
        homeButton: false,
        sceneModePicker: false,
        baseLayerPicker: false,
        navigationHelpButton: false,
        infoBox: false,
        selectionIndicator: false,
        geocoder: false, // 是否显示地名查找控件
        // imageryProvider: new UrlTemplateImageryProvider({
        //   // 配置底图获取路径
        //   url: "https://map.geoq.cn/arcgis/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
        // }),

        /**
         * 天地图加载
         */

        imageryProvider: new WebMapTileServiceImageryProvider({
          url:
            "https://t{s}.tianditu.gov.cn/vec_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=vec&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
            tiandituTk,
          subdomains: subdomains,
          layer: "tdtImgBasicLayer",
          style: "default",
          format: "image/png",
          tileMatrixSetID: "GoogleMapsCompatible",
          show: true,
          // maximumLevel: 18
        }),
      });

      // viewer.imageryLayers.addImageryProvider(
      //   new WebMapTileServiceImageryProvider({
      //     url:
      //       "https://t{s}.tianditu.gov.cn/vec_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=vec&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
      //       tiandituTk,
      //     subdomains: subdomains,
      //     layer: "tdtImgBasicLayer",
      //     style: "default",
      //     format: "image/png",
      //     tileMatrixSetID: "GoogleMapsCompatible",
      //     show: false,
      //     // maximumLevel: 18
      //   })
      // );
      // /**
      //  * 影像注记
      //  */
      viewer.imageryLayers.addImageryProvider(
        new WebMapTileServiceImageryProvider({
          url:
            "https://t{s}.tianditu.gov.cn/cva_w/wmts?service=wmts&request=GetTile&version=1.0.0&LAYER=cva&tileMatrixSet=w&TileMatrix={TileMatrix}&TileRow={TileRow}&TileCol={TileCol}&style=default.jpg&tk=" +
            tiandituTk,
          subdomains: subdomains,
          layer: "tdtCiaLayer",
          style: "default",
          format: "image/png",
          tileMatrixSetID: "GoogleMapsCompatible",
          show: true,
        })
      );
      let layer = viewer.scene.imageryLayers.get(0);

      // 改变当前地图的组织结构
      layer.minificationFilter = TextureMinificationFilter.NEAREST;
      layer.magnificationFilter = TextureMagnificationFilter.NEAREST;

      // viewer.scene.globe.maximumScreenSpaceError = 5 / 3;
      viewer.scene.globe.maximumScreenSpaceError = 1.6;
      viewer.scene.screenSpaceCameraController.minimumZoomDistance = 800;
      // viewer.scene.screenSpaceCameraController.maximumZoomDistance = 250000;
      viewer.scene.sun.show = false;
      viewer.scene.moon.show = false;
      viewer.scene.skyBox.show = false;
      viewer._cesiumWidget._creditContainer.style.display = "none"; // 隐藏版权
      viewer.scene.postProcessStages.fxaa.enabled = false;
      window.viewer = viewer;
      this.setViewTo(220, 38.874893, 25000000);
      this.ableControl(false);
      this.setPointEvent();
      this.initBlinkPoint();
    },
    ableControl(flag) {
      let scene = window.viewer.scene;
      scene.screenSpaceCameraController.enableRotate = flag;
      scene.screenSpaceCameraController.enableTranslate = flag;
      scene.screenSpaceCameraController.enableZoom = flag;
      scene.screenSpaceCameraController.enableTilt = flag;
      scene.screenSpaceCameraController.enableLook = flag;
    },
    flyTo(lon, lat, alt, callback) {
      window.viewer.camera.flyTo({
        destination: Cartesian3.fromDegrees(lon, lat, alt),
        duration: 1.5,
        orientation: {
          heading: CMath.toRadians(0.0),
          pitch: CMath.toRadians(-90.0),
          roll: 0.0,
        },
      });
      if (callback) {
        callback();
      }
    },
    setViewTo(lon, lat, alt) {
      window.viewer.camera.setView({
        // Cesium的坐标是以地心为原点，一向指向南美洲，一向指向亚洲，一向指向北极州
        // fromDegrees()方法，将经纬度和高程转换为世界坐标
        destination: Cartesian3.fromDegrees(lon, lat, alt),
        orientation: {
          // 指向
          heading: CMath.toRadians(0, 0),
          // 视角
          pitch: CMath.toRadians(-90),
          roll: 0.0,
        },
      });
    },
    reset() {
      this.ableControl(false);
      this.setViewTo(-105.098006, 38.874893, 25000000);
    },
    setMapPoints(division, address, adform) {
      window.viewer.entities.removeAll();
      let that = this;
      mapPointApi
        .getMapPoint({
          division: division,
          address: address,
          adform: adform,
        })
        .then((res) => {
          if (res) {
            res.forEach((e) => {
              that.setOneMapPoint(e, 0);
            });
            that.mapPointList = res;
            let transResult = transformGCJ2WGS(
              res[0].longitude,
              res[0].latitude
            );
            //视角重定位
            that.flyTo(transResult.lon, transResult.lat, 7000, () => {});
          }
        });
    },
    setOneMapPoint(point, alt) {
      let transResult = transformGCJ2WGS(point.longitude, point.latitude);
      point.longitude = transResult.lon;
      point.latitude = transResult.lat;
      if (point) {
        window.viewer.entities.add({
          mapPoint: point,
          position: Cartesian3.fromDegrees(
            point.longitude,
            point.latitude,
            alt
          ),
          billboard: {
            image: require("@/assets/point.png"), // default: undefined
            show: true, // default
            scale: 0.12, // default: 1.0
            color: Color.RED, // default: WHITE
          },
          label: {
            show: false,
            showBackground: true,
            font: "20px monospace",
            horizontalOrigin: HorizontalOrigin.LEFT,
            verticalOrigin: VerticalOrigin.TOP,
            pixelOffset: new Cartesian2(15, 0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
      }
    },
    showCustomerInfo() {
      let that = this;
      that.$layer.iframe({
        scrollbar: false,
        content: {
          content: CustomerInfo, //传递的组件对象
          parent: that, //当前的vue对象
          data: {
            close: () => {
              that.$layer.closeAll();
            },
          }, //props
        },
        shadeClose: false,
        // maxmin: true,//开启最大化最小化
        shade: true,
        resize: false, //是否允许拉伸，默认是不允许
        area: ["610px", "640px"],
        title: `个人信息`,
        cancel: () => { 
          this.$router.back()
        },
      });
    },
    setPointEvent() {
      let that = this;
      let user = this.$store.state.currentUser;
    if (user.customerName == null || user.customerName == "") {
          this.$message.error("请先维护个人信息");
          this.showCustomerInfo();
          return;
        }
      let handler = new ScreenSpaceEventHandler(window.viewer.scene.canvas);
      handler.setInputAction(function (click) {
        var pick = window.viewer.scene.pick(click.position);

        if (pick && pick.id && pick.id.name !== blinkPointName) {
          mapAdvertisingApi
            .getAdvertising({ mapId: pick.id.mapPoint.objectid,isEnable:1 })
            .then((res) => {
              that.$store.commit("setModalVisible",true)
              that.$layer.iframe({
                content: {
                  content: adModal, //传递的组件对象
                  parent: that, //当前的vue对象
                  data: {
                    advertisingList: res,
                  }, //props
                },
                shadeClose: false,
                shade: true,
                resize: false, //是否允许拉伸，默认是不允许
                area: ["1020px", "600px"],
                title: `广告展示-${pick.id.mapPoint.address}`,
                cancel: () => {
                  //关闭事件
                  that.$store.commit("setModalVisible",false)
                },
              });
            });
        } else if (pick && pick.id && pick.id.name === blinkPointName) {
          that.setMapPoints();
        }
      }, ScreenSpaceEventType.LEFT_CLICK);

      let currentEntity = null;
      let lastEntity = null;
      handler.setInputAction(function (movement) {
        let pickedFeature = window.viewer.scene.pick(movement.endPosition);

        if (lastEntity != null && lastEntity.id != undefined) {
          lastEntity.label.show = false;
          currentEntity.billboard.scale = 0.12;
        }
        if (defined(pickedFeature)) {
          if (pickedFeature.id != undefined) {
            currentEntity = window.viewer.entities.getById(
              pickedFeature.id._id
            );
            lastEntity = currentEntity;
            currentEntity.label.show = true;
            currentEntity.billboard.scale = 0.2;
            currentEntity.label.text = pickedFeature.id.mapPoint.address;
          }
        }
      }, ScreenSpaceEventType.MOUSE_MOVE);
    },
  },
};
