//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from "@/config/config";
export default {
  props: ["data"],
  data() {
    return {
      config: config,
      staticUrl:process.env.VUE_APP_STATIC_API
    };
  },
};
