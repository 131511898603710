//
//
//
//
//
//
//
//
//
//
//

import AdvertisingCell from "@/components/advertising/advertisingCell";
export default {
  components: {
    AdvertisingCell,
  },
  props: {
    advertisingList: {
      type: Array,
    },
  },
};
