//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import autocompleteApi from "@/api/autocomplete";
const district = [
  "黄浦区",
  "徐汇区",
  "长宁区",
  "静安区",
  "普陀区",
  "虹口区",
  "杨浦区",
  "闵行区",
  "宝山区",
  "嘉定区",
  "浦东新区",
  "金山区",
  "松江区",
  "青浦区",
  "奉贤区",
  "崇明区",
];

const adforms = [
  "非镂空",
  "半镂空",
  "镂空",
  "LED电子屏"
];
export default {
  props: {
    setMapPoints: {
      type: Function,
    },
  },
  data() {
    return {
      division: '',
      address: '',
      adform: '',
      adforms: adforms,
      district: district,
    };
  },
  mounted() { },
  methods: {
    querySearchDivision(queryString, cb) {
      let that = this;
      autocompleteApi
        .getAutocompleteWithDivision({ division: queryString })
        .then((res) => {
          if (res) {
            cb(
              res.map((e) => {
                return { value: e };
              })
            );
          }
        });
    },
    handleSelectDivision(item) {
      this.division = item.value;
    },
    querySearchAddress(queryString, cb) {
      autocompleteApi
        .getAutocompleteWithAddress({
          division: this.division,
          address: queryString,
        })
        .then((res) => {
          if (res) {
            cb(
              res.map((e) => {
                return { value: e };
              })
            );
          }
        });
    },
    handleSelectAddress(item) {
      this.address = item.value;
    },
    querySearchAdform(queryString, cb) {
      // autocompleteApi
      //   .getAutocompleteWithAdform({
      //     adform: queryString,
      //   })
      //   .then((res) => {
      //     if (res) {
      //       cb(
      //         res.map((e) => {
      //           return { value: e };
      //         })
      //       );
      //     }
      //   });
      var adforms = this.adforms;
      var results = queryString
        ? adforms.filter(this.createFilter(queryString))
        : adforms;
      // 调用 callback 返回建议列表的数据
      cb(
        results.map((e) => {
          return { value: e };
        })
      );
    },
    createFilter(queryString) {
      return (adform) => {
        return (
          adform.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        );
      };
    },
    handleSelectAdform(item) {
      this.adform = item.value;
    },
    queryMapPoint() {
      console.log(this.division, this.address, this.adform);
      if (this.division == '' && this.address == '' && this.adform == '') {
        return;
      }
      this.setMapPoints(this.division, this.address, this.adform);
    },
  },
};
