import request from '@/utils/request'
import qs from 'qs'

export function getAutocompleteWithDivision(data) {
    return request({
        url: `api/front/autocomplete/division?${qs.stringify(data)}`,
        method: 'get'
    })
}

export function getAutocompleteWithAddress(data) {
    return request({
        url: `api/front/autocomplete/address?${qs.stringify(data)}`,
        method: 'get'
    })
}

export function getAutocompleteWithAdform(data) {
    return request({
        url: `api/front/autocomplete/adform?${qs.stringify(data)}`,
        method: 'get'
    })
}

export default { getAutocompleteWithDivision, getAutocompleteWithAddress,getAutocompleteWithAdform }
